// import { Record } from 'immutable';

import { MFAParams, MultiFactorAuthMethod } from '../../../enums';

import ACTIONS from './constants';

export interface OtpStateType {
  mfaMethod: MultiFactorAuthMethod;
  visible: boolean;
  actionType: string;
  isVerified: boolean;
  isError: boolean;
  data: MFAParams;
}

// const OtpState = Record({
//   mfaMethod: MultiFactorAuthMethod.NONE,
//   visible: false,
//   actionType: '',
//   isVerified: false,
//   data: {},
// });

// const initialState = new OtpState();

const initialState = {
  mfaMethod: MultiFactorAuthMethod.NONE,
  visible: false,
  actionType: '',
  isVerified: false,
  isError: false,
  data: {},
};

export default function otpReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.OPEN_OTP_DIALOG: {
      // return state.merge(state, {
      //   ...action.data,
      //   visible: true,
      // });
      return { ...state, ...action.data, visible: true };
    }

    case ACTIONS.CLOSE_OTP_DIALOG: {
      // return state.merge(state, {
      //   ...action.data,
      //   mfaMethod: state.mfaMethod,
      //   visible: false,
      // });
      return { ...state, ...action.data, mfaMethod: state.mfaMethod, visible: false };
    }

    case ACTIONS.SET_OTP_DIALOG_STATE: {
      // return state.merge(state, {
      //   ...action.data,
      // });
      return { ...state, ...action.data };
    }

    default:
      return state;
  }
}
