import loadable from '@loadable/component';
// import Loading from '../../components/Loading';

const Change = loadable(() => import('./password_change'));
const Login = loadable(() => import('./login'));
const Logout = loadable(() => import('./logout'));
const Registration = loadable(() => import('./registration'));
const Reset = loadable(() => import('./reset_password'));

export default {
  Change,
  Login,
  Logout,
  Registration,
  Reset,
};
