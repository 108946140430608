// import { Map } from 'immutable';
import ACTIONS from './constants';

// export const initialState = Map({
//   locale: 'en',
// });

export const initialState = {
  locale: 'en',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_LOCALE: {
      // return state.set('locale', action.payload);
      return { ...state, locale: action.payload };
    }
    default: {
      return state;
    }
  }
};
