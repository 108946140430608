import reduce from 'lodash/reduce';
import { SubmissionError } from 'redux-form';

const handleSubmissionError = (errors) => {
  // const strings = Translation;
  // console.log('tesst lang ', errors);

  const newArr = reduce(
    errors,
    (result, value, key) => {
      if (Array.isArray(value)) {
        // eslint-disable-next-line no-return-assign
        value.map((errCode) => (result[key] = errCode));
      }
      return result;
    },
    {},
  );

  throw new SubmissionError(newArr);
};

export default handleSubmissionError;
