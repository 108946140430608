// import { Record } from 'immutable';

import ACTIONS from './constants';

export interface LayoutStateType {
  loading: boolean;
  dialogs: {
    dialog?: string;
    show?: boolean;
  };
}

// const LayoutState = Record<LayoutStateType>({
//   loading: false,
//   dialogs: {},
// });

// const initialState = new LayoutState();

const initialState = {
  loading: false,
  dialogs: {},
};

export default function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_LAYOUT_STATE: {
      // return state.merge(state, {
      //   ...action.data,
      // });
      return { ...state, ...action.data };
    }

    case ACTIONS.TOGGLE_DIALOG: {
      const { dialog, show } = action.data;
      // return state.mergeIn(['dialogs'], state.dialogs, {
      //   [dialog]: show,
      // });
      return { ...state, dialogs: { ...state.dialogs, [dialog]: show } };
    }

    default:
      return state;
  }
}
