import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
// import { Map } from 'immutable';

import createRootReducer from './rootReducer';

export const configureStore = ({
  initialState,
  middleware = [],
}: {
  initialState?: { [key: string]: string };
  middleware?: any[];
}) => {
  const devtools =
    typeof window !== 'undefined' &&
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionsBlacklist: [] });

  const composeEnhancers = devtools || compose;

  const store = createStore(
    createRootReducer(),
    // Map(initialState as any) as any,
    initialState,
    composeEnhancers(applyMiddleware(...[thunk].concat(...middleware))),
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./rootReducer', () => {
        store.replaceReducer(require('./rootReducer').default);
      });
    }
  }

  return store;
};

export default configureStore;
