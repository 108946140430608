/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { Switch, useHistory, useLocation } from 'react-router-dom';
// import * as SSO from './scenes/sso';
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import loadable from '@loadable/component';

// scenes
import Authentication from './scenes/authentication';
import Static from './scenes/static';
import useTheme from './hooks/useTheme';
import themes from './scenes/muiTheme';
// import { getMe } from './store/auth/actions';
import useAuth from './hooks/useAuth';
import Analytics from './utils/analytics';
import { languageKeys } from './utils/i18n';
import { POST_MESSAGE_TYPE } from './utils/helpers/network-service';
import IncognitoWarningSnackbar from './components/Notification/IncognitoWarningSnackbar';

// const WithCompanionLayout = loadable(() => import('./components/Layout/WithCompanionLayout'));
const WithSocialFooterLayout = loadable(() => import('./components/Layout/WithSocialFooterLayout'));
const WithSubheaderLayout = loadable(() => import('./components/Layout/WithSubheaderLayout'));
const RouteWithLayout = loadable(() => import('./components/Layout/RouteWithLayout'));

const useStyles = makeStyles({
  '@global': {
    body: {
      overflowY: 'scroll',
    },
    '*::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
  },
});

const AppRouter = () => {
  const firstRender = useRef(true);
  const history = useHistory();
  useTheme(firstRender.current);
  const [cookies] = useCookies(['theme']);
  const location = useLocation();

  // Check Authentication
  useAuth();
  useStyles();

  useEffect(() => {
    for (const key of languageKeys) {
      if (location.pathname.includes(`/${key}`)) {
        history.replace(`${location.pathname.slice(3)}${location.search}`);
        break;
      }
    }
  }, []);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      window.parent.postMessage({ type: POST_MESSAGE_TYPE.NAVIGATION, action, location }, '*');
    });

    return () => {
      unlisten();
    };
  }, [history]);

  React.useEffect(() => {
    Analytics.track(location);
    firstRender.current = false;
  }, [Analytics, location]);

  return (
    <ThemeProvider theme={themes[cookies.theme]}>
      <CssBaseline />
      <IncognitoWarningSnackbar />
      <Switch>
        {/** Auth pages */}
        <RouteWithLayout exact component={Static.Plans} layout={WithSubheaderLayout} path="/" />
        <RouteWithLayout
          exact
          component={Authentication.Registration}
          layout={WithSubheaderLayout}
          path="/registration"
        />
        <RouteWithLayout
          component={Authentication.Login}
          layout={WithSubheaderLayout}
          path="/login"
        />
        <RouteWithLayout
          component={Authentication.Logout}
          layout={WithSubheaderLayout}
          path="/logout"
        />
        <RouteWithLayout
          component={Authentication.Reset}
          layout={WithSubheaderLayout}
          path={['/reset-password', '/reset-mfa']}
        />
        <RouteWithLayout
          component={Authentication.Change}
          layout={WithSubheaderLayout}
          path="/password-change"
        />
        <RouteWithLayout
          exact
          component={Static.VerifiedEmail}
          layout={WithSubheaderLayout}
          path={['/email-verification', '/mfa-reset-verification']}
        />
        <RouteWithLayout
          exact
          component={Static.TermsOfService}
          layout={WithSubheaderLayout}
          path="/terms-of-service"
        />
        <RouteWithLayout
          exact
          component={Static.Privacy}
          layout={WithSubheaderLayout}
          path="/privacy-policy"
        />

        <RouteWithLayout
          exact
          component={Static.AppDownloads}
          layout={WithSocialFooterLayout}
          path="/app-download"
        />
        {/* <ThemeProvider theme={themes['white']}>
          <CssBaseline />
          <Switch>
            <RouteWithLayout
              exact
              component={Static.CompanionApp}
              layout={WithCompanionLayout}
              path="/companions-global"
            />
          </Switch>
        </ThemeProvider> */}

        {/** SSO */}
        {/* <RouteWithLayout component={Authentication.Reset} layout={WithSubheaderLayout} path='*' /> */}
      </Switch>
    </ThemeProvider>
  );
};

AppRouter.whyDidYouRender = true;

export default AppRouter;
