import axios, { Method } from 'axios';
import trim from 'lodash/trim';
import Cookie from 'universal-cookie';

import { SERVER_HOST as newAPI } from '../configs';
import { PLATFORM } from '../../../enums';

// import { authManager } from './utils/auth'
// import i18nManager from './utils/i18n/i18nManager'

const cookies = new Cookie();

const API: {
  [prop in Method]?: any;
} = {};

['get', 'post', 'put', 'delete', 'patch'].forEach((method) => {
  API[method] = (endpoint: string, options: { [key: string]: any } = {}) => {
    // const url = `https://sankaku-sso.clockwisesoftware.com/${trim(endpoint, '/')}`;
    const url = `${newAPI}/${trim(endpoint, '/')}`;
    const modifiedOptions = {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.sankaku.api+json;v=2',
        'Accept-Language': navigator.language,
        ...(cookies.get('access_token')
          ? { Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}` }
          : {}),
        ...(options.headers || {}),
        Platform: PLATFORM.SSO,
      },
    };
    return axios({ method: method as Method, url, ...modifiedOptions });
  };
});

export default API;
