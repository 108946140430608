import API from './api';

export const logIn = (login, password, mfaParams = {}) =>
  API.post('/auth/token', {
    data: { login, password, mfaParams },
  });

export const authenticate = (message, params) =>
  API.post(`/oidc/interaction/${params}/login`, { data: message.params });

export const refresh = (token) => API.post('auth/token', { data: token });

export const resetPassword = (data) => API.post('/auth/request-password-reset', { data });

export const requestMFAReset = (data) => API.post('/auth/request-mfa-reset', { data });

export const changePassword = (data) => API.post('/auth/reset-password', { data });

export const signUp = (user, entry_query, lang = 'en') =>
  API.post('users', { data: { entry_query, user, lang } });

export const getMeRequest = () => API.get('/users/me');

export const getUserLocation = () => API.get('/users/country');

export const requestValidation = (entry_query, email) =>
  API.post('/auth/request-validation', { data: { entry_query, email } });

export const verifyEmail = (verification_code, is_reset_mfa) =>
  API.post('/auth/verify-email', { data: { verification_code, is_reset_mfa } });

export const requestOtpCode = (login: string, authenticationFactor: string) =>
  API.post('/auth/request-otp', {
    data: { login, authenticationFactor },
  });

// export const verifyOtpCode = (
//   login: string,
//   code: string,
//   isRememberAddress: boolean,
// ) =>
//   API.post('/auth/verify-otp', {
//     data: { login, code, isRememberAddress },
//   });

export const verifyAddress = (login: string) =>
  API.post('/user/verify-address', {
    data: { login },
  });

export const getMfaState = (login: string) =>
  API.post('/user/mfa-state', {
    data: { login },
  });
