import { useLocation } from 'react-router';
import base64url from 'base64url';
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import {
  PAYMENTS_URL,
  PREMIUM_URL,
  SANKAKU_APP,
  SANKAKU_WORDPRESS_CLIENT_ID,
} from '../utils/configs';

export interface EntryQuery {
  client_id: string;
  redirect_uri: string;
  scope: string;
  code: string;
  state: string;
  to_payments?: string;
  route: string;
}

interface FormParams {
  state?: string;
  nonce?: any;
}

const useEntryQuery = () => {
  const { query, pathname } = useLocation();
  const [cookies] = useCookies(['theme']);
  const { i18n } = useTranslation();

  const entryQuery = query?.entry_query ? base64url.decode(query.entry_query) : '';
  const parseQuery = parse(entryQuery) as Partial<EntryQuery>;

  // Disable state for Sankaku WordPress
  const disableState = SANKAKU_WORDPRESS_CLIENT_ID === parseQuery?.client_id;
  const state = parseQuery?.state || query?.state;
  const searchParams = new URLSearchParams();

  let returnUri: URL | undefined;

  try {
    returnUri =
      parseQuery.state && parse(parseQuery.state).return_uri
        ? new URL(parse(parseQuery.state).return_uri as string)
        : undefined;
  } catch (error) {
    console.log(error);
  }

  if (i18n.language) {
    searchParams.append('lang', i18n.language);
  }
  searchParams.append('theme', cookies.theme || 'white');

  const searchParamsStr = searchParams.toString();

  let loginFormParams: FormParams = {
    state: disableState
      ? undefined
      : `${searchParamsStr}${parseQuery?.state ? `&${parseQuery?.state}` : ''}`,
    nonce: query?.nonce,
  };

  let registerFormParams: FormParams = {
    state: disableState ? undefined : `${searchParamsStr}${state ? `&${state}` : ''}`,
    nonce: query?.nonce,
  };

  if (query?.to_payments === 'true') {
    if (pathname === '/login') {
      loginFormParams = {
        state: disableState ? undefined : `${searchParamsStr}&return_uri=${PAYMENTS_URL}/auth`,
      };
    }

    if (pathname === '/registration') {
      registerFormParams = {
        state: disableState
          ? undefined
          : `${searchParamsStr}&to_payment=true${state ? `&${state}` : ''}`,
      };
    }
  }

  const plusParams = {
    ...registerFormParams,
    state: disableState
      ? undefined
      : `${
          registerFormParams.state
            ? registerFormParams.state.replace(`${SANKAKU_APP}`, `${PREMIUM_URL}`)
            : `return_uri=${PREMIUM_URL}/auth`
        }&to_premium=true`,
  };

  return { query, loginFormParams, registerFormParams, plusParams, parseQuery, returnUri };
};

export default useEntryQuery;
