export const { SERVER_HOST } = process.env;
export const { DEFAULT_THEME } = process.env;
export const { SANKAKU_APP } = process.env;
export const { SANKAKU_APP_CLIENT_ID = 'sankaku-web-app' } = process.env;
export const { PAYMENTS_URL } = process.env;
export const { SANKAKU_PAYMENT_CLIENT_ID = 'sankaku-payments' } = process.env;
export const { LEGAL_URL } = process.env;
export const { PREMIUM_URL } = process.env;
export const { APP_BASE_URL } = process.env;

export const SANKAKU_WORDPRESS_CLIENT_ID = 'sankaku-wp';
