import { Box, Button, Snackbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import detectIncognito from 'detectincognitojs';
import { Info } from '../Icon';

const useStyles = makeStyles((theme) => ({
  snackbar: {
    marginRight: '16px',
    borderRadius: 8,
    zIndex: 1299,
    backgroundColor: '#121212',
    '& .MuiSnackbarContent-root': {
      backgroundColor: '#121212',
    },
  },
  description: {
    display: 'flex',
    paddingTop: '8px',
    gap: '12px',
    backgroundColor: '#121212',
  },
  actions: {
    display: 'flex',
    backgroundColor: '#121212',
    justifyContent: 'flex-end',
  },
  dialogContainer: {
    width: 648,
    paddingBottom: '12px',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      maxWidth: 600,
    },
  },
  btn: {
    minWidth: 'auto',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(', '),
    lineHeight: '1.75 !important',
  },
}));

const IncognitoWarningSnackbar = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    detectIncognito().then((result) => {
      // only show when in incognito and web in not iframe
      if (window.self === window.top) {
        setIsOpen(result.isPrivate);
      }
    });
  }, []);

  const onClose = () => {
    setIsOpen(false);
  };

  const SnackbarContent = (
    <Box>
      <Box className={classes.description}>
        <Info />
        <Box>
          <Typography className={classes.dialogContainer}>
            {t('common-title__incognito-warning-1')}
          </Typography>
          <Typography className={classes.dialogContainer}>
            {t('common-title__incognito-warning-2')}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.actions}>
        <Button
          className={classes.btn}
          onClick={() => onClose()}
          color="primary"
          textTransform="uppercase"
        >
          {t('common-title__close')}
        </Button>
      </Box>
    </Box>
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={isOpen}
      autoHideDuration={null}
      message={SnackbarContent}
      classes={{ root: classes.snackbar }}
    />
  );
};

export default memo(IncognitoWarningSnackbar);
