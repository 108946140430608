import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import app from './app/reducer';
import auth from './auth/reducer';
import notify from './notification/reducer';
import otp from './otp/reducer';
import layout from './layout/reducer';

const createRootReducer = () =>
  combineReducers({
    app,
    auth,
    form: formReducer,
    notify,
    otp,
    layout,
  });

export default createRootReducer;
