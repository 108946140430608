export enum MultiFactorAuthMethod {
  NONE,
  MAIL,
  AUTHENTICATOR,
}

export enum AuthFactor {
  TOTP = 'totp',
  AUTHENTICATOR = 'authenticator',
}

export enum InvalidTimesMFA {
  FIRST = 5,
  SECOND = 10,
}

export enum PLATFORM {
  WHITE_APP = 'white-app',
  BLACK_APP = 'black-app',
  WEB_APP = 'web-app',
  iOS_NATIVE = 'ios-native',
  ANDROID_NATIVE = 'android-native',
  SSO = 'sso',
}

export interface MFAParams {
  login: string;
  code?: string;
  isRememberAddress?: boolean;
}
