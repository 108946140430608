import qhistory from 'qhistory';
import { stringify, parse } from 'qs';
import { createMemoryHistory, createBrowserHistory } from 'history';

declare module 'history' {
  export const createBrowserHistory: any;
}

const qHistory = qhistory(
  createBrowserHistory({
    /* history configuration options */
  }),
  stringify,
  parse,
);

export const createUniversalHistory = ({ initialEntries = [] } = {}) => {
  if (__BROWSER__) {
    const history = window.browserHistory || qHistory;
    if (process.env.NODE_ENV === 'development' && !window.browserHistory) {
      window.browserHistory = history;
    }
    return history;
  }
  return createMemoryHistory({ initialEntries } as any);
};

export default createUniversalHistory;
