import ACTIONS from './constants';

export const openNotification = (data) => ({
  type: ACTIONS.OPEN_NOTIFICATION,
  data: {
    message: data.code,
    ...data,
  },
});

export const closeNotification = () => ({
  type: ACTIONS.CLOSE_NOTIFICATION,
  data: { visible: false },
});

export const resetNotification = () => ({ type: ACTIONS.RESET_NOTIFICATION });
